/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        /****************
        **  Match Heights
        *****************/
//         $('.cta-tiles-block .tiles .tile a.tile-link').matchHeight();
        $('.cta-tiles-block .tiles .tile a.tile-link figure').matchHeight();
        $('.cta-tiles-block .tiles .tile a.tile-link .content').matchHeight();
//         $('.blog-block .blog-tiles .tile a.tile-link').matchHeight();


        /****************
        ** SMOOTH SCROLL for anchor links
        *****************/
/*
        $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 350, "linear", function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              });
            }
          }
        });
*/

        /****************
        **  Close Alert Bar
        *****************/
        if( $('.alertbar').length ) {
          if (typeof Cookies.get('alertHider') === 'undefined') {
            $('body').addClass('alertbar-engaged');
          }
        }
        $(".alertbar .closealert").on('click', function (e) {
          e.preventDefault();
          $('body').removeClass('alertbar-engaged');
          Cookies.set('alertHider', true);
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
